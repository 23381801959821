import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { Chain, createPublicClient, fallback, http, webSocket } from 'viem'

import { SUPPORTED_CHAIN_NAMES, SupportedChainId } from '../constants/chains'
import getLibrary from '../utils/getLibrary'
import { NetworkConnector } from './NetworkConnector'
import { AddressMap } from 'constants/chains'
import { DEFAULT_CHAIN_ID } from 'constants/chains'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

const INFURA_KEY = window.__RUNTIME_CONFIG__.INFURA_KEY

if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`INFURA_KEY must be a defined environment variable`)
}

const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [DEFAULT_CHAIN_ID]

export const NETWORK_URLS: AddressMap = {
  [DEFAULT_CHAIN_ID]: window.__RUNTIME_CONFIG__.RPC,
}

export const WEBSOCKET_RPCS: AddressMap = {
  [DEFAULT_CHAIN_ID]: window.__RUNTIME_CONFIG__.WEBSOCKET_RPC,
}

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: DEFAULT_CHAIN_ID,
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary(network.provider))
}

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})

export function addCredentialsToUrl(url?: string, username?: string, password?: string): string | undefined {
  if (typeof username === 'undefined' || typeof password === 'undefined') {
    return url
  }

  if (url?.startsWith('https://') || url?.startsWith('wss://')) {
    const domainIndex = url.indexOf('://') + 3
    const updatedUrl = `${url.slice(0, domainIndex)}${username}:${password}@${url.slice(domainIndex)}`
    return updatedUrl
  }
  return url
}

export function getWSSRPCUrlWithAuth(chainId: number): string | undefined {
  if (chainId !== DEFAULT_CHAIN_ID) return undefined
  return addCredentialsToUrl(
    WEBSOCKET_RPCS[chainId as SupportedChainId],
    window.__RUNTIME_CONFIG__.NAHMII_NETWORK_USERNAME,
    window.__RUNTIME_CONFIG__.NAHMII_NETWORK_PASSWORD
  )
}

const websocketRpcUrlWithCredentials = getWSSRPCUrlWithAuth(DEFAULT_CHAIN_ID)

export const CHAIN_INFO = {
  id: Number(DEFAULT_CHAIN_ID),
  name: SUPPORTED_CHAIN_NAMES[DEFAULT_CHAIN_ID as keyof typeof SUPPORTED_CHAIN_NAMES],
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  testnet: true,
  rpcUrls: {
    default: {
      http: [NETWORK_URLS[DEFAULT_CHAIN_ID as keyof typeof SUPPORTED_CHAIN_NAMES]],
      webSocket: websocketRpcUrlWithCredentials ? [websocketRpcUrlWithCredentials] : undefined,
    },
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: getExplorerLink(DEFAULT_CHAIN_ID, '', ExplorerDataType.BASE),
    },
  },
} as Chain

export const realtimeClient = createPublicClient({
  chain: CHAIN_INFO,
  transport: fallback(websocketRpcUrlWithCredentials ? [webSocket()] : [http()], { rank: true }),
})
